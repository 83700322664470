import * as React from 'react';
import { Box, Typography, Skeleton, useTheme, useMediaQuery } from '@mui/material';
import { shades } from 'theme';
import people from 'assets/enterpreneurs.jpg';

export default function Hero() {
  const [loading, setLoading] = React.useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  React.useEffect(() => {
    const img = new Image();
    img.src = people;
    img.onload = () => setLoading(false);
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',  // Full container width
        height: isMobile ? '50vh' : '100vh',  // Adjust height for mobile and desktop
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* Skeleton loading effect */}
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"  // Ensure the skeleton uses full height
          sx={{ backgroundColor: shades.black[100] }}  // Adjust the skeleton background color
        />
      ) : (
        <Box
          sx={{
            backgroundImage: `url(${people})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',  // Ensure the image also takes full height
            position: 'relative',
          }}
        >
          {/* Content Box */}
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)', // Semi-transparent overlay
              padding: '2rem',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h2" sx={{ mb: 2 }}>
              Empowering Entrepreneurs
            </Typography>
            <Typography variant="h4" sx={{ color: shades.lighgreen }}>
              Tailored and Automated Legal Support
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
