import React from 'react'
import Hero from './Hero'
import Main from './Main'


const ContactUs = () => {
  return (
    <>
    <Hero/>
    <Main/>
   
  </>
  )
}

export default ContactUs