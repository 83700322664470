import { Avatar, Grid, Typography, Box, useMediaQuery, useTheme, Pagination, Tabs, Tab, Card, CardContent, CardMedia, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link
import IP from 'assets/IP.jpg';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';

const BlogPostGrid = ({ posts, postsPerPage = 6 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // State for pagination and tab selection
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState(0);

  // State to track liked status for each post
  const [likedPosts, setLikedPosts] = useState(() => posts.reduce((acc, post) => {
    acc[post.id] = false;
    return acc;
  }, {}));

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setCurrentPage(1); // Reset to the first page when switching tabs
  };

  // Handle like button click
  const toggleLike = (postId) => {
    setLikedPosts((prevLikedPosts) => ({
      ...prevLikedPosts,
      [postId]: !prevLikedPosts[postId],
    }));
  };

  // Filter posts based on the selected tab
  const filteredPosts = posts.filter((post) => {
    const currentDate = new Date();
    const postDate = new Date(post.date);
    const daysAgo = Math.floor((currentDate - postDate) / (1000 * 60 * 60 * 24)); // Calculate days difference

    switch (activeTab) {
      case 1: // Favorite tab
        return post.likes > 50;
      case 2: // Trending tab
        return post.comments > 20;
      case 3: // Recent tab
        return daysAgo <= 7;
      default: // All tab
        return true;
    }
  });

  // Calculate total number of pages for the filtered posts
  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  // Get the posts to display on the current page
  const currentPosts = filteredPosts.slice(
    (currentPage - 1) * postsPerPage,
    currentPage * postsPerPage
  );

  return (
    <Box>
      {/* Tabs for Filtering */}
      <Tabs 
        value={activeTab} 
        onChange={handleTabChange} 
        variant={isMobile ? 'scrollable' : 'fullWidth'} 
        scrollButtons="auto" 
        aria-label="blog post categories"
        centered={!isMobile}
        sx={{ mb: 10 }}
      >
        <Tab label="All" />
        <Tab label="Favorite" />
        <Tab label="Trending" />
        <Tab label="Recent" />
      </Tabs>

      {/* Blog Posts Grid */}
      <Grid container spacing={4}>
        {currentPosts.map((post) => (
          <Grid 
            item 
            xs={12} 
            sm={6} 
            md={4} 
            lg={4}
            xl={4}
            key={post.id}
          >
            {/* Link to the Blog Post Page */}
            <Link to={`/blog/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              {/* Card Component */}
              <Card sx={{ height: '100%', boxShadow: 2, '&:hover': { boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', transform: 'scale(1.02)' }, transition: 'transform 0.3s, box-shadow 0.3s' }}>
                <CardMedia
                  component="img"
                  height={isMobile ? '150' : '200'}
                  image={post.image || IP}  // Default to IP image if none provided
                  alt={post.title}
                  sx={{
                    objectFit: 'cover',
                    borderRadius: '4px 4px 0 0',
                  }}
                />
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {post.title}
                  </Typography>
                  
                  <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                    <Avatar src={post.authorImage} alt={post.author} sx={{ width: 32, height: 32, mr: 1 }} />
                    <Typography variant="body2" color="textSecondary">
                      {post.author} | {new Date(post.date).toLocaleDateString()}
                    </Typography>
                  </Box>

                  <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                    {post.description}
                  </Typography>

                  {/* Like and Share Buttons */}
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <IconButton color="primary" aria-label="like" onClick={() => toggleLike(post.id)}>
                      {likedPosts[post.id] ? (
                        <FavoriteIcon sx={{ fontSize: '2rem', color: 'red' }} /> // Liked (filled heart)
                      ) : (
                        <FavoriteBorderIcon sx={{ fontSize: '2rem' }} /> // Not liked (outlined heart)
                      )}
                    </IconButton>
                    <IconButton color="primary" aria-label="share">
                      <ShareIcon sx={{ fontSize: '2rem' }} /> {/* Custom size for share */}
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>

      {/* Pagination Component */}
      <Box display="flex" justifyContent="center" mt={4}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default BlogPostGrid;
